/**
 * Initial requirement
 * - Tally need to refresh price, distance, transitTime when the following factor change
 *  - vehicle type
 *  - time type
 *  - locations
 *  - optimize route
 *  - return discount
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Utils } from 'utils/Utils'
import { TallyUtils } from 'utils/booking/TallyUtils'
import { CPOD, FULL_DAY, LONG_HAUL, NEW_GEN_POD, NOW, SCHEDULE } from 'constants/bookingConstants'
import _ from 'lodash'
import I18n from 'i18n/i18n'
import CommonUtils from 'utils/common'
import pinpointService from 'utils/pinpointService'

const LONG_HYPHEN = '&mdash;'
class TallyBar extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const {
      toggleTallyBar,
      timeType,
      currentStep,
      booking,
      quickChoiceID,
      pickupTime,
      selectedVehicleTypeID,
      checkLocations,
      quickChoiceSelected
    } = this.props
    const { price, distance, transitTime } = this.props
    const { price: prevPrice, distance: prevDistance, transitTime: prevTransitTime } = prevProps
    const { price: priceShow, distance: distanceShow, transitTime: transitTimeShow } = this.isShowTally()
    if ((price !== prevPrice || prevPrice) && (distance !== prevDistance || timeType === FULL_DAY)) {
      toggleTallyBar()
    }
    if (
      (currentStep === 1 || currentStep === 2) &&
      price !== null &&
      !isNaN(price) &&
      distance !== null &&
      !CommonUtils.isMultiple() &&
      (price !== prevPrice || distance !== prevDistance || transitTime !== prevTransitTime)
    ) {
      const priceSend = priceShow === LONG_HYPHEN ? 0 : price
      const transitTimeSend = transitTimeShow === LONG_HYPHEN ? 0 : Number.parseInt(transitTime / 60, 10)
      const distanceSend = distanceShow === LONG_HYPHEN ? 0 : distance
      const timeTypePayload = () => {
        let timeTypeText = ''
        switch (timeType) {
          case SCHEDULE:
            timeTypeText = 'schedule'
            break
          case FULL_DAY:
            timeTypeText = 'full_day'
            break
          case LONG_HAUL:
            timeTypeText = 'fpr'
            break
          case NOW:
            timeTypeText = 'now'
            break

          default:
            break
        }
        return timeTypeText
      }

      const convertToUTCMilliseconds = (dateTimeString) => {
        const date = new Date(dateTimeString)
        if (isNaN(date.getTime())) {
          return 'null'
        }
        const utcMilliseconds = date.getTime()
        return utcMilliseconds
      }

      CommonUtils.moengageTrackEvent('Tally Info', {
        'Total Fees': priceSend,
        Distance: distanceSend,
        Duration: transitTimeSend,
        'Booking Step': `Step ${currentStep}`,
      })
      pinpointService.trackEvent('tally_info', {
        total_fees: priceSend,
        distance: distanceSend,
        duration: transitTimeSend,
        booking_step: `step_${currentStep}`,
        time_type: quickChoiceID !== 0 ? 'null' : timeTypePayload(),
        vehicle_type_id: selectedVehicleTypeID,
        pickup_time: convertToUTCMilliseconds(pickupTime),
        quick_choice_schedule_time: quickChoiceID === 0 ? 'null' : quickChoiceSelected?.schedule_time,
        quick_choice_time_type: quickChoiceID === 0 ? 'null' : timeTypePayload(),
        sf_pod: checkLocations?.cod_pod === CPOD && booking?.cod_pod_fees ? 'true' : 'false',
        em_pod: checkLocations?.cod_pod === NEW_GEN_POD && booking?.cod_pod_fees ? 'true' : 'false',
      })
    }
  }

  isShowTally = () => {
    const { waitingAnimation, showTallyData } = this.props
    let { price, distance, transitTime } = this.props
    transitTime = transitTime !== null ? transitTime : LONG_HYPHEN

    const hasData = price !== null && distance !== null && transitTime !== null
    const showTally = waitingAnimation ? showTallyData : hasData
    price = +price === 0 ? LONG_HYPHEN : price
    distance = +distance === 0 ? LONG_HYPHEN : distance
    return { showTally, price, distance, transitTime }
  }

  render() {
    const {
      // price = LONG_HYPHEN,
      // distance = LONG_HYPHEN,
      // transitTime = LONG_HYPHEN,
      countryLanguage,
      currency,
      visibilitySetting,
      priceLabel,
    } = this.props
    // we always show long hyphen if transitTime is null. if it for the case of transit time setting is disabled

    const { showTally, price, distance, transitTime } = this.isShowTally()

    const priceText = _.isFinite(+price) ? Utils.formatFee(+price, countryLanguage, currency) : price
    const distanceText = _.isFinite(+distance)
      ? `${Utils.formatDistance(TallyUtils.roundUp(distance), countryLanguage)} ${I18n.t('webapp.booking.km')}`
      : distance
    const transitTimeText = _.isFinite(+transitTime) ? Utils.convertSecondsToTimeString(+transitTime) : transitTime

    /* eslint-disable react/no-danger */
    return (
      <div className="tally-bar-container mt15 mr10 ml10">
        {showTally && (
          <div className="tally-bar Radius-Default pt5 pr5 pb5 pl5 flex flex-row">
            {visibilitySetting.price && priceText ? (
              <div className="tally-bar-row-item flex flex-column">
                <div className="value" dangerouslySetInnerHTML={{ __html: priceText }} />
                <div className="text">{priceLabel || I18n.t('webapp.tally_bar.price')}</div>
              </div>
            ) : null}
            {visibilitySetting.distance && distanceText ? (
              <div className="tally-bar-row-item flex flex-column">
                <div className="value" dangerouslySetInnerHTML={{ __html: distanceText }} />
                <div className="text">{I18n.t('webapp.tally_bar.distance')}</div>
              </div>
            ) : null}
            {visibilitySetting.transitTime && transitTimeText ? (
              <div className="tally-bar-row-item flex flex-column">
                <div className="value" dangerouslySetInnerHTML={{ __html: transitTimeText }} />
                <div className="text">{I18n.t('webapp.tally_bar.transit')}</div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    )
  }
}

TallyBar.propTypes = {
  price: PropTypes.number,
  distance: PropTypes.number,
  transitTime: PropTypes.number,
  countryLanguage: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  visibilitySetting: PropTypes.shape({}),
  quickChoiceSelected: PropTypes.shape({}),
  priceLabel: PropTypes.string,
  showTallyData: PropTypes.bool,
  toggleTallyBar: PropTypes.func,
  waitingAnimation: PropTypes.bool,
  timeType: PropTypes.string,
  checkLocations: PropTypes.shape({}),
  quickChoiceID: PropTypes.number,
  selectedVehicleTypeID: PropTypes.number,
  pickupTime: PropTypes.string,
}

TallyBar.defaultProps = {
  price: null,
  distance: null,
  transitTime: null,
  visibilitySetting: {
    price: true,
    distance: true,
    transitTime: true,
  },
  priceLabel: null,
  showTallyData: true,
  toggleTallyBar: () => undefined,
  waitingAnimation: false,
  timeType: '',
}

export default TallyBar
